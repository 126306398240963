<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else-if="!isNotFound">
		<div class="main-wrapper-large">
			<PreOrderForm
				:pre-order-id="preOrderId"
				:pre-order-general-info="generalInfo"
				:pre-order-campaign-sku="campaignSku"
				:pre-order-product-price-display="productPriceDisplay"
				:pre-order-schedule-info="scheduleInfo"
				:pre-order-limit="limit"
				:pre-order-delivery-shipping-info="deliveryShippingInfo"
				:pre-order-label="label"
				:pre-order-campaign-info="campaignInfo"
				:pre-order-terms-and-conditions="termsAndConditions"
				:pre-order-pickup-at-store="pickupAtStore"
				:pre-order-grab-and-go="grabAndGo"
				is-edit
			>
				<template v-slot:header>
					<div class="page-header mb-5">
						<h2 class="typo-h4">{{ preOrder.name }}</h2>
						<div class="summary">
							<p class="typo-body-2 m-0 p-0">
								Last updated by: <span class="color-hypertext">{{ updatedBy }}</span><span class="date color-black-45"> | {{ preOrder.updatedAt }}</span>
							</p>
							<p class="typo-body-2 m-0">
								Created by: <span class="color-hypertext">{{ createdBy }}</span><span class="date color-black-45"> | {{ preOrder.createdAt }}</span>
							</p>
						</div>
					</div>
				</template>
			</PreOrderForm>
		</div>
	</div>
	<Error404 v-else />
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapState, mapGetters } from 'vuex';
import PreOrderForm from '@/components/PreOrderForm.vue';
import Error404 from '@/components/Error404.vue';

import { DEFAULT_SYSTEM_ADMIN_NAME } from '../enums/general';
import { transformedPreOrderToMultipleSectionFromAPI } from '../assets/js/transform/preorders';

export default {
	name: 'PreOrderEdit',
	components: {
		PreOrderForm,
		Error404,
	},
	data() {
		return {
			isLoading: true,
			preOrderId: this.$route.params.id,
			generalInfo: {},
			campaignSku: {},
			productPriceDisplay: {},
			scheduleInfo: {},
			limit: {},
			deliveryShippingInfo: {},
			label: {},
			campaignInfo: {},
			termsAndConditions: {},
			pickupAtStore: {},
			grabAndGo: {},
		};
	},
	computed: {
		...mapState('preorders', {
			edit: 'edit',
		}),
		...mapGetters({
			preOrder: 'preorders/preOrderInfo',
		}),
		updatedBy() {
			if (this.edit && this.edit.data && this.edit.data.updated_by) {
				return this.edit.data.updated_by.username;
			}

			return DEFAULT_SYSTEM_ADMIN_NAME;
		},
		createdBy() {
			if (this.edit && this.edit.data && this.edit.data.created_by) {
				return this.edit.data.created_by.username;
			}

			return DEFAULT_SYSTEM_ADMIN_NAME;
		},
		isNotFound() {
			return isEmpty(this.edit) || isEmpty(this.edit.data);
		},
	},
	async created() {
		await this.getPreOrder(this.preOrderId);

		this.extractData();
	},
	methods: {
		...mapActions({
			deletePreOrder: 'preorders/deletePreOrder',
			getPreOrder: 'preorders/getPreOrder',
			updatePreOrder: 'preorders/updatePreOrder',
		}),
		extractData() {
			if (this.edit && this.edit.data) {
				const preOrder = this.edit.data;

				const {
					generalInfo,
					campaignSku,
					productPriceDisplay,
					scheduleInfo,
					limit,
					deliveryShippingInfo,
					label,
					campaignInfo,
					termsAndConditions,
					pickupAtStore,
					grabAndGo,
				} = transformedPreOrderToMultipleSectionFromAPI(preOrder) || {};

				this.generalInfo = generalInfo;
				this.campaignSku = campaignSku;
				this.productPriceDisplay = productPriceDisplay;
				this.scheduleInfo = scheduleInfo;
				this.limit = limit;
				this.deliveryShippingInfo = deliveryShippingInfo;
				this.label = label;
				this.campaignInfo = campaignInfo;
				this.termsAndConditions = termsAndConditions;
				this.pickupAtStore = pickupAtStore;
				this.grabAndGo = grabAndGo;
			}

			this.isLoading = false;
		},
	},
};
</script>
